



















































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private biaozhuType: any;
  @Prop()
  private type: any;
  @Watch("type", { immediate: true })
  private typeChange() {
    this.detail =
      this.data[this.type == "left" ? "left_eye" : "right_eye"]["黑睛"];
    this.imgs = this.data[this.type == "left" ? "l_eye_image" : "r_eye_image"];
  }
  @Prop()
  private data: any;
  @Watch("data", { immediate: true, deep: true })
  private dataChange() {
    this.detail =
      this.data[this.type == "left" ? "left_eye" : "right_eye"]["黑睛"];
    this.imgs = this.data[this.type == "left" ? "l_eye_image" : "r_eye_image"];
  }
  private imgs: any = [];
  private detail: any = {
    color: {
      color: "",
    },
    质地: {
      质地: "",
    },

    坑洞: {
      坑洞: "",
      位置: [],
    },
    辐射状黑线: {
      辐射状黑线: "",
      位置: [],
    },
    同心圆状白线: {
      同心圆状白线: "",
      局部: [],
      全部: [],
    },
    黑斑黑点: {
      黑斑黑点: "",
      位置: [],
    },
    黑睛七环: {
      代谢环: "",
      肠道环: "",
      环七: "",
    },
  };
  private youwu: any = ["有", "无"];
  private colors: any = ["正常", "变浅", "变深", "不均匀"];
  private weizhi: any = [
    "1点",
    "2点",
    "3点",
    "4点",
    "5点",
    "6点",
    "7点",
    "8点",
    "9点",
    "10点",
    "11点",
    "12点",
    "全眼",
  ];
  private weizhi2: any = [
    "1点",
    "2点",
    "3点",
    "4点",
    "5点",
    "6点",
    "7点",
    "8点",
    "9点",
    "10点",
    "11点",
    "12点",
  ];
  private quanbu: any = ["1圈", "2圈", "3圈", "4圈及以上"];
  private daixiehuan: any = ["正常", "偏斜", "消失", "不均匀"];
  private changdaohuan: any = ["正常", "扩大", "缩小", "疏松", "坑洞", "黑斑"];
  private huanqi: any = ["正常", "皮垢环", "蓝环", "老化弧", "钠环"];
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private formatTooltip(val: any) {
    return val + "度";
  }
  private showData(key: any) {
    const data = this.data["轨迹"][key];
    let arr: any = [];
    if (data && data.length > 0) {
      this.imgs.forEach((ele: any) => {
        const index = data.findIndex((val: any) => {
          return val.id == ele;
        });
        if (index != -1) {
          arr.push(data[index]);
        }
      });
    }
    return arr;
  }
  private getArr(id: any) {
    let arr: any = [];
    if (
      this.data["轨迹"] &&
      this.data["轨迹"]["黑睛"] &&
      this.data["轨迹"]["黑睛"].length > 0
    ) {
      const index = this.data["轨迹"]["黑睛"].findIndex((val: any) => {
        return val.name == id;
      });
      if (index !== -1) {
        arr = this.data["轨迹"]["黑睛"][index].children;
      }
    }
    return arr;
  }
  private deleteHeijing(item: any) {
    const d: any = JSON.parse(JSON.stringify(item));
    d.type = "黑睛";
    this.$emit("deleteBiaozhu", d);
  }
  private startBiaozhu() {
    this.$emit("startBiaozhu", "黑睛");
  }
  private valueChange() {
    const d = JSON.parse(JSON.stringify(this.data));
    d[this.type == "left" ? "left_eye" : "right_eye"]["黑睛"] = this.detail;
    this.$emit("change", d);
  }
}
